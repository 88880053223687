import "animate.css/animate.min.css"
//Package CSS
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import React, { Component, Suspense } from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
//Template CSS Style
import "./assets/css/responsive.css"
import "../src/style.min.css"
import "./assets/css/color/color-red.min.css"
import "./assets/css/responsive.min.css"
import "./assets/icofont/icofont.min.css"
// Admission
const EspritIngenieur = React.lazy(() => import("./pages/Admission/EspritIngenieur"))
// formation
const CyclePrepa = React.lazy(() => import("./pages/Formation/CyclePrepa"))
const Genie_Informatique = React.lazy(() => import("./pages/Formation/CycleIngenieur/Genie_Informatique"))
const Genie_EmbarqueTelecom = React.lazy(() => import("./pages/Formation/CycleIngenieur/Genie_EmbarqueTelecom"))
const SystemeAuto = React.lazy(() => import("./pages/Formation/CycleIngenieur/SystemeAuto"))
// const ElectroniqueEmbarque = React.lazy(() => import("./pages/Formation/CycleIngenieur/ElectroniqueEmbarque"))
// const Froid_Climatisation = React.lazy(() => import("./pages/Formation/CycleIngenieur/Froid_Climatisation"))
const Genie_Industriel = React.lazy(() => import("./pages/Formation/CycleIngenieur/Genie_Industriel"))

const Genie_Mecanique = React.lazy(() => import("./pages/Formation/CycleIngenieur/Genie_Mecanique"))
const CoursSoir = React.lazy(() => import("./pages/Formation/CoursSoir"))
const DoubleDiplome = React.lazy(() => import("./pages/Formation/DoubleDiplome"))
const Certfications = React.lazy(() => import("./pages/Formation/Certifications"))
const Enseignants = React.lazy(() => import("./pages/Formation/Enseignants"))
//Esprit Monastir
const ChoisirEsprit = React.lazy(() => import("./pages/EspritMonastir/ChoisirEsprit"))
const ConseilScientifique = React.lazy(() => import("./pages/EspritMonastir/ConseilScientifique"))
const EspritEnChiffre = React.lazy(() => import("./pages/EspritMonastir/EspritEnChiffre"))
const FAQ = React.lazy(() => import("./pages/EspritMonastir/FAQ"))
const MotDirection = React.lazy(() => import("./pages/EspritMonastir/MotDirection"))
const Presentation = React.lazy(() => import("./pages/EspritMonastir/Presentation"))
const Direction = React.lazy(() => import("./pages/EspritMonastir/Direction"))
const Honoris = React.lazy(() => import("./pages/EspritMonastir/Honoris"))
// Entreprise
const Partenariats = React.lazy(() => import("./pages/Entreprise/Partenariats"))
const Alternance = React.lazy(() => import("./pages/Entreprise/Alternance"))
const VisitesIndus = React.lazy(() => import("./pages/Entreprise/VisitesIndus"))



const Mobilite = React.lazy(() => import("./pages/International/Mobilite"))
const  UniversityPart= React.lazy(() => import("./pages/International/UniversityPart"))
const Erasmus = React.lazy(() => import("./pages/International/Erasmus"))


const Stages = React.lazy(() => import("./pages/VieEstudiantine/Stages"))
const Temoignages = React.lazy(() => import("./pages/VieEstudiantine/Temoignages"))
const Clubs = React.lazy(() => import("./pages/VieEstudiantine/Clubs"))
const Club = React.lazy(() => import("./pages/VieEstudiantine/Club"))
const Photos =React.lazy(() => import("./pages/Actualite/Photos"))
const Evenement =React.lazy(() => import("./pages/Actualite/Evenement"))
const Videos =React.lazy(() => import("./pages/Actualite/Videos"))
const Contact = React.lazy(() => import("./pages/Contact"))
const Footer = React.lazy(() => import("./components/layout/Footer"))
const ScrollUpBtn = React.lazy(() => import("./components/layout/ScrollUp"))
const Home = React.lazy(() => import("./pages/Home"))
//const Contactinfo = React.lazy(() => import("./pages/InternationalDegreeProgram/Contact"))

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <section className="route-section">
            <Suspense
              fallback={
                <>
                  <svg className="spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                  </svg>
                  <style
                    dangerouslySetInnerHTML={{
                      __html: [
                        "   html,body {",
                        "  height: 100%;",
                        "   background-image: linear-gradient(-105deg, #cd2122, #212529) !important;",
                        "}",
                      ].join("\n"),
                    }}></style>
                </>
              }>
              <Switch>
                <Route path="/contact" render={props => <Contact {...props} />} />
                {/* <Route path="/Contact-information" render={props => <Contactinfo {...props} />} /> */}
                <Route path="/admission/esprit-ingenieur" render={props => <EspritIngenieur {...props} />} />
                <Route path="/formation/cycle-preparatoire" render={props => <CyclePrepa {...props} />} />

                <Route path="/formation/cycle-dingenieur/genie-informatique" render={props => <Genie_Informatique {...props} />} />
                <Route path="/formation/cycle-dingenieur/genie-mecatronique" render={props => <Genie_Mecanique {...props} />} />
                <Route path="/formation/cycle-dingenieur/embarque-telecom" render={props => <Genie_EmbarqueTelecom {...props} />} />
                <Route path="/formation/cycle-dingenieur/systémes-automatisés" render={props => <SystemeAuto {...props} />} />
                <Route path="/formation/cycle-dingenieur/genie-industriel" render={props => <Genie_Industriel {...props} />} />

                {/* <Route path="/formation/cycle-dingenieur/embarque-telecom" render={props => <Genie_Industriel {...props} />} /> */}
                {/* <Route path="/formation/cycle-dingenieur/froid-climatisation" render={props => <Froid_Climatisation {...props} />} /> */}
                {/* <Route path="/formation/cycle-dingenieur/systemes-automatises-et-maintenance-industrielle" render={props => <SystemeAuto {...props} />} /> */}
                {/* <Route path="/formation/cycle-dingenieur/electronique-embarquee-et-telecoms" render={props => <ElectroniqueEmbarque {...props} />} /> */}
                <Route path="/formation/cours-du-soir" render={props => <CoursSoir {...props} />} />
                <Route path="/formation/double-diplomation" render={props => <DoubleDiplome {...props} />} />
                <Route path="/certifications" render={props => <Certfications {...props} />} />
                <Route path="/enseignants" render={props => <Enseignants {...props} />} />
              


                <Route path="/presentation" render={props => <Presentation {...props} />} />
                <Route path="/mot-de-la-directrice" render={props => <MotDirection {...props} />} />
                <Route path="/choisir-esprit-monastir" render={props => <ChoisirEsprit {...props} />} />
                <Route path="/equipe-de-direction-administratif-et-pedagogique" render={props => <Direction {...props} />} />
                <Route path="/conseil-scientifique" render={props => <ConseilScientifique {...props} />} />
                <Route path="/ESPRIMS-en-chiffre" render={props => <EspritEnChiffre {...props} />} />
                <Route path="/partenariats" render={props => <Partenariats {...props} />} />
                <Route path="/formation-par-alternance" render={props => <Alternance {...props} />} />
                <Route path="/visites-industrielles" render={props => <VisitesIndus {...props} />} />
                <Route path="/faq" render={props => <FAQ {...props} />} />
                <Route path="/honoris-united-universities" render={props => <Honoris {...props} />} />

                <Route path="/mobilite-des-etudiants-enseignants" render={props => <Mobilite {...props} />} />
                <Route path="/les-universites-internationales-partenaires" render={props => <UniversityPart {...props} />} />
                <Route path="/charte-erasmus" render={props => <Erasmus {...props} />} />

                 <Route path="/stages-et-projets" render={props => <Stages {...props} />} />
                 <Route path="/temoignages" render={props => <Temoignages {...props} />} />
                 <Route path="/clubs" render={props => <Clubs {...props} />} />
                 <Route path="/club/:id" render={props => <Club {...props} />} />

                 <Route path="/gallerie-photos" render={props => <Photos {...props} />} />
                 <Route path="/conference-evenements-scientifiques" render={props => <Evenement {...props} />} />
                 <Route path="/gallerie-videos" render={props => <Videos {...props} />} />
                <Route path="/" exact render={() => <Home />} />
                <Redirect to="/" />
              </Switch>
              <Footer />
              <ScrollUpBtn />

            </Suspense>
          </section>
        </div >
      </BrowserRouter >
    )
  }
}
